
var ignoreStoreSelector=false;

var ua=''+navigator.userAgent;
if (ua.indexOf('APIs-Google')<0 && ua.indexOf('Mediapartners-Google')<0 && ua.indexOf('AdsBot-Google-Mobile')<0 && ua.indexOf('AdsBot-Google')<0 &&
    ua.indexOf('Googlebot-')<0 && ua.indexOf('Googlebot/')<0 && ua.indexOf('AdsBot-Google-Mobile-Apps')<0 && ua.indexOf('bing.com/bingbot')<0 &&
    ua.indexOf('deepcrawl.com/bot')<0 && ua.indexOf('BingBot (')<0 && ua.indexOf('Baiduspider/')<0 && ua.indexOf('Pinterest/')<0 && ua.indexOf('Google Favicon')<0 &&
    ua.indexOf('DuckDuckGo')<0 && ua.indexOf('Yahoo! Slurp')<0 && ua.indexOf('MJ12bot')<0 && ua.indexOf('proximic.com/')<0 && ua.indexOf('Sogou web spider')<0 &&
    ua.indexOf('Slackbot-LinkExpanding')<0 && ua.indexOf('YandexBot/')<0 && ua.indexOf('AhrefsBot/')<0 && ua.indexOf('Twitterbot/')<0 && ua.indexOf('google.com/+/web/snippet')<0 &&
    ua.indexOf('BingPreview/')<0 && ua.indexOf('360Spider(')<0 && ua.indexOf('YodaoBot/')<0 && ua.indexOf('msnbot/')<0 && ua.indexOf('Sosospider+')<0 &&
    ua.indexOf('facebookexternalhit/')<0 && ua.indexOf('Facebot')<0) {
    saveCountry();
    $(document).ready(function() {
        setTimeout(updateStoreSelector, 1000);
    });
}

function saveCountry() {
    if (!$.cookie('slCCodes')) {
        $.getJSON('https://api.ipstack.com/check?access_key=1013a0e5619c2054a66ac712aba870c8&legacy=1', function(data){
            //console.log('data = ',data);
            var stores = {
                "AF": {
                    "Name": "Afghanistan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AX": {
                    "Name": "Aland Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AL": {
                    "Name": "Albania",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "DZ": {
                    "Name": "Algeria",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AS": {
                    "Name": "American Samoa",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AD": {
                    "Name": "Andorra",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AO": {
                    "Name": "Angola",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AI": {
                    "Name": "Anguilla",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AQ": {
                    "Name": "Antarctica",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AG": {
                    "Name": "Antigua And Barbuda",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AR": {
                    "Name": "Argentina",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AM": {
                    "Name": "Armenia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AW": {
                    "Name": "Aruba",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AU": {
                    "Name": "Australia",
                    "Store": "https:\/\/aus.moanabikini.com"
                },
                "AT": {
                    "Name": "Austria",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AZ": {
                    "Name": "Azerbaijan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BS": {
                    "Name": "Bahamas",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BH": {
                    "Name": "Bahrain",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BD": {
                    "Name": "Bangladesh",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BB": {
                    "Name": "Barbados",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BY": {
                    "Name": "Belarus",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BE": {
                    "Name": "Belgium",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BZ": {
                    "Name": "Belize",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BJ": {
                    "Name": "Benin",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BM": {
                    "Name": "Bermuda",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BT": {
                    "Name": "Bhutan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BO": {
                    "Name": "Bolivia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BA": {
                    "Name": "Bosnia And Herzegovina",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BW": {
                    "Name": "Botswana",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BV": {
                    "Name": "Bouvet Island",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BR": {
                    "Name": "Brazil",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IO": {
                    "Name": "British Indian Ocean Territory",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BN": {
                    "Name": "Brunei Darussalam",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BG": {
                    "Name": "Bulgaria",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BF": {
                    "Name": "Burkina Faso",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BI": {
                    "Name": "Burundi",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KH": {
                    "Name": "Cambodia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CM": {
                    "Name": "Cameroon",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CA": {
                    "Name": "Canada",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CV": {
                    "Name": "Cape Verde",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KY": {
                    "Name": "Cayman Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CF": {
                    "Name": "Central African Republic",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TD": {
                    "Name": "Chad",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CL": {
                    "Name": "Chile",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CN": {
                    "Name": "China",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CX": {
                    "Name": "Christmas Island",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CC": {
                    "Name": "Cocos (Keeling) Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CO": {
                    "Name": "Colombia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KM": {
                    "Name": "Comoros",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CG": {
                    "Name": "Congo",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CD": {
                    "Name": "Congo, Democratic Republic",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CK": {
                    "Name": "Cook Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CR": {
                    "Name": "Costa Rica",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CI": {
                    "Name": "Cote D'Ivoire",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "HR": {
                    "Name": "Croatia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CU": {
                    "Name": "Cuba",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CY": {
                    "Name": "Cyprus",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CZ": {
                    "Name": "Czech Republic",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "DK": {
                    "Name": "Denmark",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "DJ": {
                    "Name": "Djibouti",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "DM": {
                    "Name": "Dominica",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "DO": {
                    "Name": "Dominican Republic",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "EC": {
                    "Name": "Ecuador",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "EG": {
                    "Name": "Egypt",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SV": {
                    "Name": "El Salvador",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GQ": {
                    "Name": "Equatorial Guinea",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ER": {
                    "Name": "Eritrea",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "EE": {
                    "Name": "Estonia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ET": {
                    "Name": "Ethiopia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "FK": {
                    "Name": "Falkland Islands (Malvinas)",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "FO": {
                    "Name": "Faroe Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "FJ": {
                    "Name": "Fiji",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "FI": {
                    "Name": "Finland",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "FR": {
                    "Name": "France",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GF": {
                    "Name": "French Guiana",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PF": {
                    "Name": "French Polynesia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TF": {
                    "Name": "French Southern Territories",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GA": {
                    "Name": "Gabon",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GM": {
                    "Name": "Gambia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GE": {
                    "Name": "Georgia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "DE": {
                    "Name": "Germany",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GH": {
                    "Name": "Ghana",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GI": {
                    "Name": "Gibraltar",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GR": {
                    "Name": "Greece",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GL": {
                    "Name": "Greenland",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GD": {
                    "Name": "Grenada",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GP": {
                    "Name": "Guadeloupe",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GU": {
                    "Name": "Guam",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GT": {
                    "Name": "Guatemala",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GG": {
                    "Name": "Guernsey",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GN": {
                    "Name": "Guinea",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GW": {
                    "Name": "Guinea-Bissau",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GY": {
                    "Name": "Guyana",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "HT": {
                    "Name": "Haiti",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "HM": {
                    "Name": "Heard Island & Mcdonald Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "VA": {
                    "Name": "Holy See (Vatican City State)",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "HN": {
                    "Name": "Honduras",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "HK": {
                    "Name": "Hong Kong",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "HU": {
                    "Name": "Hungary",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IS": {
                    "Name": "Iceland",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IN": {
                    "Name": "India",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ID": {
                    "Name": "Indonesia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IR": {
                    "Name": "Iran, Islamic Republic Of",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IQ": {
                    "Name": "Iraq",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IE": {
                    "Name": "Ireland",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IM": {
                    "Name": "Isle Of Man",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IL": {
                    "Name": "Israel",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "IT": {
                    "Name": "Italy",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "JM": {
                    "Name": "Jamaica",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "JP": {
                    "Name": "Japan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "JE": {
                    "Name": "Jersey",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "JO": {
                    "Name": "Jordan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KZ": {
                    "Name": "Kazakhstan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KE": {
                    "Name": "Kenya",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KI": {
                    "Name": "Kiribati",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KR": {
                    "Name": "Korea",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KW": {
                    "Name": "Kuwait",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KG": {
                    "Name": "Kyrgyzstan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LA": {
                    "Name": "Lao People's Democratic Republic",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LV": {
                    "Name": "Latvia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LB": {
                    "Name": "Lebanon",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LS": {
                    "Name": "Lesotho",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LR": {
                    "Name": "Liberia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LY": {
                    "Name": "Libyan Arab Jamahiriya",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LI": {
                    "Name": "Liechtenstein",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LT": {
                    "Name": "Lithuania",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LU": {
                    "Name": "Luxembourg",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MO": {
                    "Name": "Macao",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MK": {
                    "Name": "Macedonia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MG": {
                    "Name": "Madagascar",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MW": {
                    "Name": "Malawi",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MY": {
                    "Name": "Malaysia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MV": {
                    "Name": "Maldives",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ML": {
                    "Name": "Mali",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MT": {
                    "Name": "Malta",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MH": {
                    "Name": "Marshall Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MQ": {
                    "Name": "Martinique",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MR": {
                    "Name": "Mauritania",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MU": {
                    "Name": "Mauritius",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "YT": {
                    "Name": "Mayotte",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MX": {
                    "Name": "Mexico",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "FM": {
                    "Name": "Micronesia, Federated States Of",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MD": {
                    "Name": "Moldova",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MC": {
                    "Name": "Monaco",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MN": {
                    "Name": "Mongolia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ME": {
                    "Name": "Montenegro",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MS": {
                    "Name": "Montserrat",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MA": {
                    "Name": "Morocco",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MZ": {
                    "Name": "Mozambique",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MM": {
                    "Name": "Myanmar",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NA": {
                    "Name": "Namibia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NR": {
                    "Name": "Nauru",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NP": {
                    "Name": "Nepal",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NL": {
                    "Name": "Netherlands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AN": {
                    "Name": "Netherlands Antilles",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NC": {
                    "Name": "New Caledonia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NZ": {
                    "Name": "New Zealand",
                    "Store": "https:\/\/aus.moanabikini.com\/"
                },
                "NI": {
                    "Name": "Nicaragua",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NE": {
                    "Name": "Niger",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NG": {
                    "Name": "Nigeria",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NU": {
                    "Name": "Niue",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NF": {
                    "Name": "Norfolk Island",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MP": {
                    "Name": "Northern Mariana Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "NO": {
                    "Name": "Norway",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "OM": {
                    "Name": "Oman",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PK": {
                    "Name": "Pakistan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PW": {
                    "Name": "Palau",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PS": {
                    "Name": "Palestinian Territory, Occupied",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PA": {
                    "Name": "Panama",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PG": {
                    "Name": "Papua New Guinea",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PY": {
                    "Name": "Paraguay",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PE": {
                    "Name": "Peru",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PH": {
                    "Name": "Philippines",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PN": {
                    "Name": "Pitcairn",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PL": {
                    "Name": "Poland",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PT": {
                    "Name": "Portugal",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PR": {
                    "Name": "Puerto Rico",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "QA": {
                    "Name": "Qatar",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "RE": {
                    "Name": "Reunion",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "RO": {
                    "Name": "Romania",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "RU": {
                    "Name": "Russian Federation",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "RW": {
                    "Name": "Rwanda",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "BL": {
                    "Name": "Saint Barthelemy",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SH": {
                    "Name": "Saint Helena",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "KN": {
                    "Name": "Saint Kitts And Nevis",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LC": {
                    "Name": "Saint Lucia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "MF": {
                    "Name": "Saint Martin",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "PM": {
                    "Name": "Saint Pierre And Miquelon",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "VC": {
                    "Name": "Saint Vincent And Grenadines",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "WS": {
                    "Name": "Samoa",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SM": {
                    "Name": "San Marino",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ST": {
                    "Name": "Sao Tome And Principe",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SA": {
                    "Name": "Saudi Arabia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SN": {
                    "Name": "Senegal",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "RS": {
                    "Name": "Serbia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SC": {
                    "Name": "Seychelles",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SL": {
                    "Name": "Sierra Leone",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SG": {
                    "Name": "Singapore",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SK": {
                    "Name": "Slovakia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SI": {
                    "Name": "Slovenia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SB": {
                    "Name": "Solomon Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SO": {
                    "Name": "Somalia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ZA": {
                    "Name": "South Africa",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GS": {
                    "Name": "South Georgia And Sandwich Isl.",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ES": {
                    "Name": "Spain",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "LK": {
                    "Name": "Sri Lanka",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SD": {
                    "Name": "Sudan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SR": {
                    "Name": "Suriname",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SJ": {
                    "Name": "Svalbard And Jan Mayen",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SZ": {
                    "Name": "Swaziland",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SE": {
                    "Name": "Sweden",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "CH": {
                    "Name": "Switzerland",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "SY": {
                    "Name": "Syrian Arab Republic",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TW": {
                    "Name": "Taiwan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TJ": {
                    "Name": "Tajikistan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TZ": {
                    "Name": "Tanzania",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TH": {
                    "Name": "Thailand",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TL": {
                    "Name": "Timor-Leste",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TG": {
                    "Name": "Togo",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TK": {
                    "Name": "Tokelau",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TO": {
                    "Name": "Tonga",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TT": {
                    "Name": "Trinidad And Tobago",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TN": {
                    "Name": "Tunisia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TR": {
                    "Name": "Turkey",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TM": {
                    "Name": "Turkmenistan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TC": {
                    "Name": "Turks And Caicos Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "TV": {
                    "Name": "Tuvalu",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "UG": {
                    "Name": "Uganda",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "UA": {
                    "Name": "Ukraine",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "AE": {
                    "Name": "United Arab Emirates",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "GB": {
                    "Name": "United Kingdom",
                    "Store": "https:\/\/international.moanabikini.com"
                },
                "US": {
                    "Name": "United States",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "UM": {
                    "Name": "United States Outlying Islands",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "UY": {
                    "Name": "Uruguay",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "UZ": {
                    "Name": "Uzbekistan",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "VU": {
                    "Name": "Vanuatu",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "VE": {
                    "Name": "Venezuela",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "VN": {
                    "Name": "Viet Nam",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "VG": {
                    "Name": "Virgin Islands, British",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "VI": {
                    "Name": "Virgin Islands, U.S.",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "WF": {
                    "Name": "Wallis And Futuna",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "EH": {
                    "Name": "Western Sahara",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "YE": {
                    "Name": "Yemen",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ZM": {
                    "Name": "Zambia",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                },
                "ZW": {
                    "Name": "Zimbabwe",
                    "Store": "https:\/\/northamerica.moanabikini.com\/"
                }
            }

            var countryCode = data.country_code;
            var store;

            if (typeof stores[countryCode] === 'undefined') {
                store = 'https://international.moanabikini.com/';
            }
            else {
                store = stores[countryCode]['Store'];
            }

            $.cookie('slCCodes', JSON.stringify({'Country': stores[countryCode]['Name'], 'Code': countryCode, 'Store': store}))
            //console.log('store = ',store,' / countryCode = ',countryCode);

            checkStoreRedirect();
        });

    } else {

        checkStoreRedirect();

    }
}

function getCountry() {
    if ($.cookie('slCCodes')) {
        return JSON.parse($.cookie('slCCodes'));
    }
}

function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


var rdr = getParameterByName('rdr');

if (rdr) {
    $.cookie('dontRedirect', 1);
}

function updateStoreSelector() {

    if (ignoreStoreSelector) {
        return;
    }

    var storeSelectorSpans = $('#storeSelector .countryCode')
    var storeSelectorUrl = $('#storeSelectorUrl');

    var details = getCountry();

    //console.log('details = ',details);
    if (typeof details === 'undefined') {
        // if at this point `details` remains undefined, its due to the AJAX request to the geo server not yet being complete
        // sooooo, run this function again in a second and hope it's there if not rinse and repeat.
        setTimeout(updateStoreSelector, 1000)

        return;
    }

    if (checkStoreRedirect()) {
        return;
    }

    storeSelectorSpans.each(function() {
        $(this).html(details.Country);
    });

    if (details.Store == 'https://international.moanabikini.com/') {
        storeSelectorUrl.find('span').html('International');
        $('#storeSelector .countryCode.last').html('International');
    }
    storeSelectorUrl.attr('href', details.Store);

}

function checkStoreRedirect()
{
    var href=''+window.location;
    var details = getCountry();

    if (typeof details === 'undefined') {
        return false;
    }

    if (!$.cookie('dontRedirect') && (details.Code != 'AU' && details.Code != 'NZ')) {
        window.location = details.Store;
        ignoreStoreSelector=true;
        return;
    }

    return false;
}
